<template>
    <div>
        <!-- TODO: Hedaer image optimization -->
        <div id="news-header" class="entry-short" v-bind:style="{ backgroundImage: 'url(' + newsListHeaderImage + ')' }">
            <div class="context">
                <h1>News</h1>
            </div>
        </div>

        <AlternatingList :list="alternatingList" contentType="newsArticles"/>

        <Pagination
            v-if="total > 1"
            path="/news"
            :total="total"
            :page="page"
            scrollAnchor="news-header"
        />
    </div>
</template>

<script>
    import AlternatingList from '@/components/AlternatingList'
    import Pagination from '@/components/Pagination'
    import { totalNewsArticlesQuery, newsArticlesQuery, globalOptionQuery } from '@/queries'
    import { dateFormat } from '@/helper'
    import { config } from '@/config'

    export default {
        name: 'News',

        data() {
            return {
                totalNewsArticles: 0,
                newsArticles: null,
                dateFormat: dateFormat,
                newsListHeader: null,
                limit: 10
            }
        },

        components: {
            AlternatingList,
            Pagination
        },

        apollo: {
            totalNewsArticles: {
                query: totalNewsArticlesQuery,
                fetchPolicy: 'network-only',
                notifyOnNetworkStatusChange: true,
                update: data => data.newsArticles.meta.pagination.total
            },

            newsArticles: {
                query: newsArticlesQuery,
                variables() {
                    return {
                        limit: this.limit,
                        start: this.start
                    }
                },
                fetchPolicy: 'network-only',
                notifyOnNetworkStatusChange: true,
            },

            newsListHeader: {
                query: globalOptionQuery,
                variables: {
                    optionId: 'news-list-header'
                },
                fetchPolicy: 'cache-first',
                update: data => data.globalOptions.data[0]
            }

        },

        metaInfo() {
            var title = config.globalPageTitle + ' - News'
            return {
                title: title
            }
        },

        computed: {
            page() {
                return this.$route.query.page ? this.$route.query.page : 1
            },

            start() {
                return (this.page - 1) * this.limit
            },

            total() {
                return Math.ceil(this.totalNewsArticles / this.limit)
            },

            newsListHeaderImage() {
                if(this.newsListHeader){
                    return this.getImageUrl(this.localize(this.newsListHeader.attributes.image).data.attributes.url)
                }
            },

            alternatingList() {
                var list = []
                var self = this
                if(this.newsArticles){
                    var articlesSorted = this.newsArticles.data.slice().sort(function(a, b){
                        var displayDateA = a.attributes.dateOverride ? new Date(a.attributes.dateOverride) : new Date(a.attributes.createdAt)
                        var displayDateB = b.attributes.dateOverride ? new Date(b.attributes.dateOverride) : new Date(b.attributes.createdAt)
                        return displayDateA > displayDateB ? -1 : 1
                    })
                    articlesSorted.forEach(function(article, index){
                        list.push({
                            index: index,
                            image: article.attributes.titleImage ? self.localize(article.attributes.titleImage).data.attributes : null,
                            title: self.localize(article.attributes.title),
                            url: self.url(article),
                            subComponent: {
                                title: self.localize(article.attributes.title),
                                date: self.displayDate(article),
                                excerpt: self.localize(article.attributes.excerpt),
                                url: self.url(article)
                            }
                        })
                    })
                }
                return list
            }
        },

        methods: {
            url(article) {
                return '/news/' + article.attributes.urlKey
            },

            displayDate(article) {
                return article.attributes.dateOverride ? dateFormat(new Date(article.attributes.dateOverride)) : dateFormat(new Date(article.attributes.createdAt))
            }
        }
    }
</script>

<style lang="scss">
    #news-header {
        background-size: cover;
    }
</style>
